a:link, a:visited {
  text-decoration: none;
  color: #33869c;
}
a:hover, a:visited:hover {
  color: #83cfe3;
}

.App {
  display: flex;
  flex-direction: column;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ffffff;
  background-color: #282c34;
}

.maven-link:link, .maven-link:visited {
  color: #8d3939;
  text-decoration: none;
  font-weight: bold;
}
.maven-link:hover, .maven-link:visited:hover {
  color: #c92424;
  text-decoration: none;
  font-weight: bold;
}

.gradle-link:link, .gradle-link:visited {
  color: #33869c;
  text-decoration: none;
  font-weight: bold;
}

.gradle-link:hover, .gradle-link:visited:hover {
  color: #83cfe3;
  text-decoration: none;
  font-weight: bold;
}

.public-or-login-card {
  background-color: #363a42;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  margin-top: 50px;
  padding: 25px 75px 35px;
  justify-content: center;
  text-align: center;
}


/**/
.main-login-suggestion a {
  text-decoration: none;
}

.main-login-suggestion a:link, a:visited {
  color: #33869c;
}

.main-login-suggestion a:hover, a:visited:hover {
  color: #83cfe3;
}

.main-login-suggestion {
  display: flex;
  flex-direction: column;
  background-color: #363a42;
  padding: 35px 75px 25px;
  border-radius: 15px;
  margin-top: 25px;
  text-align: center;
}

.already-registered-log-in {
  margin-top: 15px;
  font-size: 14px;
}

.already-registered-log-in {
  margin-top: 15px;
  font-size: 14px;
}